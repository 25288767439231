<script setup lang="ts">
import { Action } from "@/lib/useToast";

const props = withDefaults(
  defineProps<{
    level: "info" | "success" | "warning" | "error";
    message: string;
    persistent?: boolean;
    ttl?: number;
    action?: Action;
  }>(),
  {
    persistent: false,
    ttl: 5000,
  }
);

const emit = defineEmits<{
  (e: "dismiss"): void;
}>();

const ICONS = {
  info: "info",
  success: "check_circle",
  warning: "warning",
  error: "error",
};

const CLASSES = {
  info: ["tw-bg-blue-300", "tw-text-blue-900"],
  success: ["tw-bg-success-300", "tw-text-success-900"],
  warning: ["tw-bg-yellow-300", "tw-text-yellow-900"],
  error: ["tw-bg-red-300", "tw-text-red-900"],
};

const autoDismissTimeout = props.persistent
  ? null
  : window.setTimeout(() => dismiss(), props.ttl);

function dismiss() {
  if (autoDismissTimeout) {
    window.clearTimeout(autoDismissTimeout);
  }
  emit("dismiss");
}
</script>

<template>
  <div
    class="tw-rounded tw-py-2 tw-px-4 tw-inline-flex tw-gap-2 tw-items-center tw-font-bold max-md:tw-flex-wrap max-md:tw-w-full"
    :class="CLASSES[level]"
  >
    <t-icon>{{ ICONS[level] }}</t-icon>
    <span
      class="tw-grow md:tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden"
    >
      {{ message }}
    </span>
    <span class="tw-grow" />
    <TBtn href="mailto:support@stationa.com" v-if="level === 'error'">
      Get Help
    </TBtn>
    <TBtn @click="action.fn" v-if="action">{{ action.label }}</TBtn>
    <TBtn @click="dismiss">Dismiss</TBtn>
  </div>
</template>
