<template>
  <v-app class="tw-bg-app-background" :dark="dark">
    <div class="tw-w-full tw-min-h-full tw-flex">
      <nav-drawer
        class="tw-fixed tw-top-0 tw-w-full tw-max-h-svh tw-z-[100] md:tw-sticky md:tw-h-svh md:tw-min-w-60 md:tw-max-w-min"
      />
      <div class="tw-flex-grow tw-mt-16 md:tw-mt-0">
        <router-view :key="refreshKey" v-if="canAccess" />
        <div v-else class="tw-flex tw-h-full tw-items-center tw-justify-center">
          <t-card v-if="isAuthed">
            <t-card-title>
              <t-icon left>lock</t-icon>
              <h3 class="headline">You are unable to access this content</h3>
            </t-card-title>
            <t-card-text>
              <p class="subheading">
                It looks like your account doesn't have permission to access
                this secure content.
              </p>
              <p class="grey--text mb-0">
                If this problem persists, please reach out to us directly at
                <a href="mailto:support@stationa.com" target="_blank">
                  support@stationa.com </a
                >.
              </p>
            </t-card-text>
            <t-card-actions class="tw-justify-end">
              <TBtn flat :to="{ name: 'dashboard' }">Go to Dashboard</TBtn>
            </t-card-actions>
          </t-card>
          <t-card v-else-if="!isAuthed && !params.invite_token">
            <t-card-title>
              <t-icon left>login</t-icon>
              <h3 class="headline">Sign in to access this content</h3>
            </t-card-title>
            <t-card-text>
              <p class="subheading">
                Please sign in to access this secure content.
              </p>
              <p class="grey--text mb-0">
                If this problem persists, please reach out to us directly at
                <a href="mailto:support@stationa.com" target="_blank"
                  >support@stationa.com</a
                >.
              </p>
            </t-card-text>
            <t-card-actions class="tw-justify-end">
              <TBtn flat :href="accountsAppUrl">Sign in / Sign up</TBtn>
            </t-card-actions>
          </t-card>
        </div>
      </div>
    </div>
    <t-toast-portal />

    <!-- Note that the order of this logic is important -->
    <terms-dialog v-if="isAuthed && !termsAreUpToDate" />
    <!-- We show this dialog even if unauthed if we have an invite token -->
    <org-invite-confirmation-dialog
      v-else-if="params.invite_token"
      :invite-token="params.invite_token"
      :current-organization-id="organizationId"
      :current-organization-name="organizationName"
      :is-authed="isAuthed"
      @close="removeInviteTokenParam"
    />
    <org-type-select-dialog
      v-else-if="!hasOrg && isAuthed"
      :auto-select="params.set_org_type"
      @select="removeSetOrgTypeParam"
    />
  </v-app>
</template>

<style lang="scss">
#app {
  .faint {
    opacity: 0.7;
  }
  [disabled] {
    cursor: not-allowed;
    &[role="listitem"] {
      opacity: 0.7;
    }
  }
  .disabled {
    opacity: 0.7;
  }
  .font-weight-medium {
    font-weight: 600 !important;
  }
  h2,
  h3,
  .body-2,
  .v-tabs__div {
    font-weight: 600;
  }
  strong {
    font-weight: 600;
  }
}
</style>

<script>
import { computed } from "vue";

import { getAccountsAppUrl, useAuth } from "./lib/useAuth";
import { useTheme } from "./lib/useTheme";
import { useProviderProfile } from "./lib/useProvider";
import { useBuyerProfile } from "./lib/useBuyer";
import { useTerms } from "./lib/useTerms";
import { useUrlSearchParams } from "@vueuse/core";

import NavDrawer from "@/components/NavDrawer.vue";
import OrgInviteConfirmationDialog from "@/components/OrgInviteConfirmationDialog.vue";
import OrgTypeSelectDialog from "@/components/OrgTypeSelectDialog.vue";
import TermsDialog from "@/components/TermsDialog.vue";

const MENU_TOOLTIP_DURATION = 10000;

export default {
  name: "App",
  components: {
    NavDrawer,
    OrgInviteConfirmationDialog,
    OrgTypeSelectDialog,
    TermsDialog,
  },
  setup() {
    const {
      isAuthed,
      isSuper,
      isBuyer,
      isProvider,
      buyerId,
      providerId,
      hasOrg,
    } = useAuth();
    const { dark, primaryColor } = useTheme();
    const { termsAreUpToDate } = useTerms();
    const params = useUrlSearchParams("history");

    const { buyer } = isBuyer.value ? useBuyerProfile(buyerId) : {};
    const { provider } = isProvider.value ? useProviderProfile(providerId) : {};
    const organizationName = computed(() => {
      if (buyer && buyer.value) {
        return buyer.value.common_org_name;
      } else if (provider && provider.value) {
        return provider.value.common_org_name;
      } else {
        return null;
      }
    });
    return {
      dark,
      primaryColor,
      isAuthed,
      isSuper,
      isBuyer,
      isProvider,
      organizationName,
      hasOrg,
      params,
      termsAreUpToDate,
    };
  },
  data() {
    return {
      nav: false,
      introduceMenu: true,
      organizationId: null,
      refreshKey: 0,
    };
  },
  metaInfo: {
    title: "App",
    titleTemplate: "Station A - %s",
  },
  computed: {
    canAccess() {
      let allow = true;

      if (this.$route.meta && this.$route.meta.requires) {
        const requirements = this.$route.meta.requires;
        if (requirements.isAuthed) {
          allow = allow && this.isAuthed;
        }
        if (requirements.isBuyer) {
          allow = allow && this.isBuyer;
        }
        if (requirements.isProvider) {
          allow = allow && this.isProvider;
        }
        if (requirements.isSuper) {
          allow = allow && this.isSuper;
        }
      }

      return allow;
    },
    accountsAppUrl() {
      return getAccountsAppUrl();
    },
  },
  methods: {
    hidePreApp() {
      // NOTE: This is a bit hacky, but it works for now
      const preApp = window.document.getElementById("pre-app");
      if (preApp) {
        preApp.parentElement.removeChild(preApp);
      }
    },
    removeSetOrgTypeParam() {
      const { set_org_type, ...rest } = this.params;
      this.params.set_org_type = null;
      /*
       * If we have the `set_org_type` URL query param, remove it
       * Otherwise, do nothing to avoid duplicated navigation
       */
      if (set_org_type) {
        this.$router.replace({ query: rest });
      }
    },
    removeInviteTokenParam() {
      const { invite_token, ...rest } = this.params;
      this.params.invite_token = null;
      /*
       * If we have the `invite_token` URL query param, remove it
       * We use 'push' to allow the user to see the previous invite link
       * in the browser history
       */
      if (invite_token) {
        this.$router.push({ query: rest });
      }
      // We do this so, any information in the router-view has the updated org info
      this.refreshKey += 1;
    },
  },
  mounted() {
    setTimeout(() => (this.introduceMenu = false), MENU_TOOLTIP_DURATION);
  },
  created() {
    this.hidePreApp();
  },
  watch: {
    primaryColor: {
      handler() {
        this.$vuetify.theme.primary = this.primaryColor;
      },
      immediate: true,
    },
  },
};
</script>
