import { ref, watch, Ref } from "vue";
import { toRef, MaybeRefOrGetter, useAsyncState } from "@vueuse/core";
import router from "../router";

import { Provider } from "@/lib/OrgTypes";
import { ProvidersClient } from "@/lib/ProviderAPI";
import { Proposal } from "@/lib/useProposal";

export function useProviderProfile(
  providerId: MaybeRefOrGetter<string | undefined>
) {
  const reactiveProviderId = toRef(providerId);

  const provider = ref<Provider | undefined>();
  const initialLoadComplete = ref(false);
  const providerLoading = ref(true);
  const providerClient = new ProvidersClient();

  const reloadProvider = async () => {
    providerLoading.value = true;
    try {
      if (!reactiveProviderId.value) {
        provider.value = undefined;
        return;
      }
      const maybeProvider = await providerClient.getProvider(
        reactiveProviderId.value
      );
      if (maybeProvider) {
        provider.value = maybeProvider;
      }
      return provider;
    } catch (e) {
      router.push({ name: "not-found" }).catch(() => {});
    } finally {
      providerLoading.value = false;
      initialLoadComplete.value = true;
    }
  };

  watch(reactiveProviderId, reloadProvider, { immediate: true });

  return {
    provider,
    initialLoadComplete,
    reloadProvider,
    providerLoading,
  };
}

export function isNonEmptyValue(value: any) {
  // This method controls whether or not we want to show that this field is
  // complete/has a valid answer to the user
  if (value === null) return false;

  // Since empty arrays are truthy, we need to check that it is not empty
  if (Array.isArray(value) && value.length === 0) return false;

  // We want to allow a user to input 0
  if (typeof value === "number" || typeof value === "boolean") return true;

  // This allows for attachments to be marked as complete
  return !!value;
}

export function providerProfileCompleteness() {
  // Note that all of these functions require the caller to pass in the provider
  // object. This is because we want to use them before we have saved the provider
  // in the backend, so we want to be able to pass a 'dirty' provider object
  // In addition, before *some* of these functions were reactive and some were not
  // this unifies the approach

  function getPercentComplete(
    provider: Provider | undefined,
    fieldsToCheck: string[]
  ) {
    if (!provider || fieldsToCheck.length === 0) {
      return 0;
    }

    const requiredFieldsInFieldsToCheck =
      provider.required_fields_for_proposal_submission.filter((field: string) =>
        fieldsToCheck.includes(field)
      );
    const completedRequiredFields = fieldsToCheck.filter(
      (field: string) =>
        isNonEmptyValue(provider[field]) &&
        provider.required_fields_for_proposal_submission!.includes(field)
    );
    // if no required fields, return 100%
    if (requiredFieldsInFieldsToCheck.length === 0) {
      return 100;
    }
    return (
      (completedRequiredFields.length / requiredFieldsInFieldsToCheck.length) *
      100
    );
  }

  function percentComplete(
    provider: Provider | undefined,
    fields?: string[] | undefined
  ) {
    const fieldsToCheck =
      fields || provider?.required_fields_for_proposal_submission || [];
    const rawPercentComplete = getPercentComplete(provider, fieldsToCheck);
    return Math.round(rawPercentComplete);
  }

  function isComplete(provider: Provider | undefined) {
    return provider ? percentComplete(provider) === 100 : 0;
  }

  // pass in the specific fields you want checked if applicable
  // otherwise it will check all required fields
  function progressChipStatus(
    provider: Provider | undefined,
    fields?: string[]
  ) {
    const pc = percentComplete(provider, fields);
    if (!pc) {
      return "not-started";
    }
    if (pc === 100) {
      return "completed";
    } else if (pc < 100) {
      return "in-progress";
    } else {
      return "not-started";
    }
  }

  return {
    percentComplete,
    isComplete,
    getPercentComplete,
    progressChipStatus,
  };
}

export function useProviderProfilesForProposals(proposals: Ref<Proposal[]>) {
  const providerClient = new ProvidersClient();

  const {
    state: proposalProviders,
    isReady: ready,
    isLoading: providersLoading,
    error: error,
    execute: reloadProviders,
  } = useAsyncState(
    async () => {
      const proposalProviders: { [key: string]: Provider } = {};
      if (proposals.value) {
        for (const proposal of proposals.value) {
          const provider = await providerClient.getProvider(
            proposal.providerId
          );
          if (provider) {
            proposalProviders[proposal.id] = provider;
          }
        }
      }
      return proposalProviders;
    },
    {},
    { resetOnExecute: false }
  );

  watch(proposals, async () => await reloadProviders());

  return {
    proposalProviders,
    providersLoading,
    ready,
    error,
  };
}
