<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  color?: "purple" | "green" | "yellow" | "blue";
}>();

const dynamicClasses = computed(() => {
  if (props.color === "yellow") {
    return [
      "tw-bg-yellow-300",
      "dark:!tw-bg-yellow-700",
      "!tw-text-yellow-800",
      "dark:!tw-text-yellow-100",
    ];
  }
  if (props.color === "green") {
    return [
      "!tw-bg-success-500/50",
      "!tw-text-success-800",
      "dark:!tw-text-success-100",
    ];
  }
  if (props.color === "blue") {
    return [
      "!tw-bg-provider-500/50",
      "!tw-text-provider-800",
      "dark:!tw-text-provider-100",
    ];
  }
  if (props.color === "purple") {
    return [
      "!tw-bg-purple-500/50",
      "tw-text-purple-900",
      "dark:tw-text-purple-200",
    ];
  }
  return ["tw-bg-white", "dark:tw-bg-zinc-800"];
});
</script>
<template>
  <div class="tw-rounded-xl" :class="dynamicClasses">
    <slot />
  </div>
</template>
