<script setup lang="ts">
const props = defineProps<{
  left?: boolean;
  right?: boolean;
  small?: boolean;
  large?: boolean;
  outline?: boolean;
}>();

if (props.left && props.right) {
  console.warn("Either `left` or `right` should be used, not both");
}

if (props.small && props.large) {
  console.warn("Either `small` or `large` should be used, not both");
}

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void;
}>();
</script>

<style scoped>
.icon-filled {
  /** Adjust these settings as needed */
  font-variation-settings: "FILL" 1, "wght" 400;
}
.icon-outlined {
  /** Adjust these settings as needed */
  font-variation-settings: "FILL" 0, "wght" 400;
}
</style>

<template>
  <span
    class="material-symbols-rounded !tw-leading-none tw-text-current tw-inline-flex tw-items-center tw-justify-center tw-align-text-bottom"
    :class="{
      'icon-filled': !outline,
      'icon-outlined': outline,
      'tw-mr-2': left,
      'tw-ml-2': right,
      'tw-text-base': small,
      'tw-text-4xl': large,
      'tw-text-xl': !(small || large),
    }"
    @click="emit('click', $event)"
  >
    <slot />
  </span>
</template>
