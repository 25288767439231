<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  readOnly?: boolean;
  value?: boolean | null;
}>();
const emit = defineEmits<{
  (e: "change", value: boolean): void;
  (e: "input", value: boolean): void;
}>();

const dynamicClasses = computed(() => {
  const classes = [
    "tw-checkbox",
    "tw-sta-input",
    "focus:!tw-border-success-400",
    "dark:focus:!tw-border-success-600",
    "!tw-w-min",
  ];
  return classes.join(" ");
});
</script>

<template>
  <input
    type="checkbox"
    :disabled="props.readOnly"
    :checked="!!value"
    @change="emit('change', !value)"
    @input="emit('input', !value)"
    :class="dynamicClasses"
  />
</template>
